var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageTitle',{attrs:{"title":_vm.$t('user.title')}}),_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('button.search'),"single-line":"","hide-details":""},on:{"change":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-container',[_c('v-data-table',{staticClass:"elevation-24",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"server-items-length":_vm.totalUsers,"footer-props":{
        itemsPerPageOptions: [5,10,15]
      },"locale":_vm.$i18n.locale,"loading":_vm.loading,"dense":"","sort-by":"id","item-key":"id","show-select":""},on:{"update:options":function($event){_vm.options=$event},"input":function($event){_vm.usersSelectedForDelete = $event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.usersSelectedForDelete.length)?_c('v-btn',{attrs:{"block":"","color":"error"},on:{"click":function($event){_vm.dialogDelete = true}}},[_vm._v(" Remove Selected ")]):_vm._e()]},proxy:true},{key:"item.cpf",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.cpf)+" ")])]}},{key:"item.dataLimiteBloqueio",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.dataLimiteBloqueio)+" ")])]}},{key:"item.roles",fn:function(ref){
      var item = ref.item;
return _vm._l((item.roles),function(group,index){return _c('span',{key:index,staticClass:"text-caption"},[_vm._v(" "+_vm._s(group.name)+" ")])})}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Name","disabled":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"E-mail","disabled":""},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"CPF","disabled":""},model:{value:(_vm.editedItem.cpf),callback:function ($$v) {_vm.$set(_vm.editedItem, "cpf", $$v)},expression:"editedItem.cpf"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bloqueado para solicitações de Pré-Análise até:","clearable":"","persistent-hint":"","prepend-icon":"mdi mdi-calendar"},on:{"click:clear":function($event){_vm.editedItem.dataLimiteBloqueio = null}},model:{value:(_vm.editedItem.dataLimiteBloqueio),callback:function ($$v) {_vm.$set(_vm.editedItem, "dataLimiteBloqueio", $$v)},expression:"editedItem.dataLimiteBloqueio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-select',{attrs:{"items":_vm.groupsAvailable,"item-text":"name","item-value":"name","hint":"Grupos atribuídos","multiple":"","chips":"","persistent-hint":"","dense":""},model:{value:(_vm.groupsSelected),callback:function ($$v) {_vm.groupsSelected=$$v},expression:"groupsSelected"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to remove? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.deleteUsersSelected()}}},[_vm._v(" Yes ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"value":_vm.snackbarDeleteShow,"color":_vm.snackbarDeleteColor,"absolute":"","right":"","rounded":"pill","top":""}},[_vm._v(" "+_vm._s(_vm.snackbarDeleteText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }