<template>
  <div>
    <PageTitle :title="$t('user.title')" />
    <v-container>
      <v-text-field
        v-model.lazy.trim="search"
        append-icon="mdi-magnify"
        :label="$t('button.search')"
        single-line
        hide-details
        @change="getDataFromApi()"
      />
    </v-container>
    <v-container>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="users"
        :options.sync="options"
        :server-items-length="totalUsers"
        :footer-props="{
          itemsPerPageOptions: [5,10,15]
        }"
        :locale="$i18n.locale"
        :loading="loading"
        dense
        sort-by="id"
        item-key="id"
        show-select
        class="elevation-24"
        @input="usersSelectedForDelete = $event"
      >
        <template v-slot:footer>
          <v-btn
            v-if="usersSelectedForDelete.length"
            block
            color="error"
            @click="dialogDelete = true"
          >
            Remove Selected
          </v-btn>
        </template>
        <template v-slot:[`item.cpf`]="{item}">
          <div class="text-no-wrap">
            {{ item.cpf }}
          </div>
        </template>
        <template v-slot:[`item.dataLimiteBloqueio`]="{item}">
          <div class="text-no-wrap">
            {{ item.dataLimiteBloqueio }}
          </div>
        </template>
        <template v-slot:[`item.roles`]="{item}">
          <span
            v-for="(group, index) in item.roles"
            :key="index"
            class="text-caption"
          >
            {{ group.name }}
          </span>
        </template>
        <template v-slot:top>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.email"
                        label="E-mail"
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.cpf"
                        label="CPF"
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{on, attrs}">
                          <v-text-field
                            v-model="editedItem.dataLimiteBloqueio"
                            label="Bloqueado para solicitações de Pré-Análise até:"
                            clearable
                            persistent-hint
                            prepend-icon="mdi mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="editedItem.dataLimiteBloqueio = null"
                          />
                        </template>
                        <v-date-picker
                          v-model="date"
                          :locale="$i18n.locale"
                          no-title
                          @input="menu = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-select
                        v-model="groupsSelected"
                        :items="groupsAvailable"
                        item-text="name"
                        item-value="name"
                        hint="Grupos atribuídos"
                        multiple
                        chips
                        persistent-hint
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{item}">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <!-- <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
        </template>
      </v-data-table>
    </v-container>
    <v-dialog
      v-model="dialogDelete"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to remove?
        </v-card-title>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="dialogDelete = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="deleteUsersSelected()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      :value="snackbarDeleteShow"
      :color="snackbarDeleteColor"
      absolute
      right
      rounded="pill"
      top
    >
      {{ snackbarDeleteText }}
    </v-snackbar>
  </div>
</template>

<script>
import PageTitle from '@/views/common/widget/WidgetTitulo.vue';
import router from '@/router';

export default {
  name: 'User',
  components: { PageTitle },
  data() {
    return {
      search: '',
      selected: [],
      loading: true,
      totalUsers: 0,
      users: [],
      options: {},
      // Variáveis do Dialog de edição
      editedIndex: -1,
      editedItem: {
        name: '',
        cpf: '',
      },
      dialog: false,
      // Variáveis DatePicker
      menu: false,
      date: '',
      // Variáveis Seleção de Grupos
      groupsSelected: [],
      groupsAvailable: [],
      // Remoção de múltiplos usuários
      usersSelectedForDelete: [],
      dialogDelete: false,
      snackbarDeleteShow: false,
      snackbarDeleteColor: '',
      snackbarDeleteText: '',
    };
  },
  computed: {
    // Computed usado para tradução do cabeçalho
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('user.table.name'), value: 'name' },
        { text: 'E-mail', value: 'email' },
        { text: 'CPF', value: 'cpf' },
        { text: this.$t('user.table.groups'), value: 'roles', sortable: false },
        { text: this.$t('user.table.blockedRequestPreAnalysis'), value: 'dataLimiteBloqueio' },
        { text: this.$t('user.table.actions'), value: 'actions' },
      ];
    },
  },
  watch: {
    // Botões do footer da tabela
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    // Dialog de edição
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    // Criado para pegar data do DatePicker e colocar no TextField
    date() {
      this.editedItem.dataLimiteBloqueio = this.date;
    },
    // groupsSelected() {
    //   return [{ name: this.groupsSelected }];
    // },
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;
      try {
        await this.$axios.get(`${process.env.VUE_APP_URL_API}/users?page=${this.options.page}&per_page=${this.options.itemsPerPage}&sort_by=${this.options.sortBy}&sort_desc=${this.options.sortDesc}&total_users=${this.totalUsers}&q=${this.search}`, { headers: { Authorization: `Bearer ${router.app.$keycloak.token}` } })
          .then((response) => {
            this.users = response.data.users.data;
            this.totalUsers = response.data.users.total;
            this.groupsAvailable = response.data.groups;
          }).finally(() => {
            this.loading = false;
          })
          .catch((error) => {
            console.log('getUsers', error);
          });
      } catch (err) {
        console.warn('getUsers', err);
      }
    },
    // Métodos para o Dialog de edição
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = { ...item };
      this.groupsSelected = this.editedItem.roles.map((i) => ({ name: i.name }));
      this.dialog = true;
    },
    // deleteItem(item) {
    //   const index = this.users.indexOf(item);
    //   this.users.splice(index, 1);
    // },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    save() {
      this.editedItem.roles = this.groupsSelected;
      if (typeof this.groupsSelected[0] === 'string') {
        this.editedItem.roles = this.groupsSelected.map((i) => ({ name: i }));
      }
      Object.assign(this.users[this.editedIndex], this.editedItem);
      this.groupsSelected = [];
      this.close();
    },
    async deleteUsersSelected() {
      const ids = this.usersSelectedForDelete.map((e) => e.id);
      this.dialogDelete = false;
      this.loading = true;
      try {
        this.snackbarDeleteShow = false;
        await this.$axios.delete(`${process.env.VUE_APP_URL_API}/users/delete-multiple/`, { data: { ids }, headers: { Authorization: `Bearer ${router.app.$keycloak.token}` } })
          .then(() => {
            this.snackbarDeleteShow = true;
            this.snackbarDeleteColor = 'success';
            this.snackbarDeleteText = { en: 'Successfully removed', pt: 'Removido com sucesso', es: 'Eliminado con éxito' }[this.$i18n.locale];
            this.usersSelectedForDelete = [];
            this.getDataFromApi();
          }).finally(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.snackbarDeleteShow = true;
            this.snackbarDeleteColor = 'error';
            this.snackbarDeleteText = error;
            this.usersSelectedForDelete = [];
            console.log('delete1Users', error);
          });
      } catch (err) {
        this.snackbarDeleteShow = true;
        this.snackbarDeleteColor = 'error';
        this.snackbarDeleteText = err;
        this.usersSelectedForDelete = [];
        console.warn('deleteUsers', err);
      }
    },
  },
};
</script>
