<template>
  <div>
    <v-row
      dense
      no-gutters
      align="center"
      justify="center"
      class="layout__content-title-background-color"
    >
      <v-col cols="12">
        <v-container>
          <h2 class="white--text">
            {{ title }}
          </h2>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'WidgetTitulo',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
  }),
};
</script>
